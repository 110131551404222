import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function Header() {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="lg:my-8 container mx-auto">
      <div className="bg-white inline-block font-mono text-green-400 font-medium tracking-wide p-4 rounded">
        {site.siteMetadata.title}
      </div>
    </div>
  );
}

export default Header;
