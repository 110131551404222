import PropTypes from "prop-types";
import React from "react";
import Footer from '../components/footer';
import Header from '../components/header';

function Layout({ children }) {
  return (
      <div className="h-screen p-4 bg-gray-300 text-gray-700 hero-pattern">
        <Header />
        <main className="container flex mx-auto flex-wrap md:flex-row-reverse">
          {children}
        </main>
        <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
